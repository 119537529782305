<template src="./createVoucherHomepage.html" lang=""></template>
<style lang="scss">
@import "./createVoucherHomepage.scss";
</style>

<script>
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { RadioButton } from "@progress/kendo-vue-inputs";
import Breadcrumb from "../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import { TabStrip, TabStripTab } from "@progress/kendo-vue-layout";
import AppSnackBarVue from "@/components/common/AppSnackBar/AppSnackBar.vue";
// import CreateVoucherWithEmail from "../createVoucher/createVoucherWithEmail/createVoucherWithEmail.vue";
export default {
  name: "CreateVoucherHomePage",
  components: {
    "k-input": Input,
    kbutton: Button,
    Breadcrumb,
    radiobutton: RadioButton,
    tabstrip: TabStrip,
    tabstripTab: TabStripTab,
    AppSnackBar: AppSnackBarVue,
  },
  data() {
    return {
      itemCode: "allVoucher",
      moduleCode: "voucher",
      creationTypeSerial: 1,
      voucherName: "",
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      clubName: this.$route.query.club_name,
    };
  },

  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
  },

  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },
    dataValidation() {
      if (!this.voucherName) {
        this.showNotification("Voucher program name is required.", "warning");
        return false;
      }
      if (this.voucherName.length > 75) {
        this.showNotification(
          "Voucher program name should not exceed 75 characters.",
          "warning"
        );
        return false;
      }
      if (this.voucherName.trim().length === 0) {
        this.showNotification(
          "Voucher program name should not be empty.",
          "warning"
        );
        return false;
      }
      return true;
    },
    voucherTypeClickHandler(type) {
      this.creationTypeSerial = type;
      // 1 : "Create Voucher code in Email"
      // 2 : "Create Voucher code and barcode in an email"
      // 3 : "Create Voucher code,barcode and Digital Pass in an email"
      // 4 :  Digital pass image

      if (type === 1) {
        this.$refs.createWithEmail.classList.add("active");
        this.$refs.createWithBarcode.classList.remove("active");
        this.$refs.createWithDigitalPss.classList.remove("active");
      }
      if (type === 2) {
        this.$refs.createWithBarcode.classList.add("active");
        this.$refs.createWithEmail.classList.remove("active");
        this.$refs.createWithDigitalPss.classList.remove("active");
      }
      if (type === 3) {
        this.$refs.createWithDigitalPss.classList.add("active");
        this.$refs.createWithEmail.classList.remove("active");
        this.$refs.createWithBarcode.classList.remove("active");
      }
    },
    doNavigateToVoucherHomepage() {
      this.$router.push({
        name: "AllVoucher",
      });
    },
    handleCreateEvent() {
      if (this.dataValidation()) {
        this.$router.push({
          name: "CreateVoucherCampaign",
          query: {
            ...this.$route.query,
            voucher_name: this.voucherName,
            club_name: this.clubName,
            voucher_type:
              this.creationTypeSerial > 3 ? 3 : this.creationTypeSerial,
          },
        });
      }
    },
  },
};
</script>
<style lang=""></style>
