<div class="dashboard-right-inner">
    <Breadcrumb :id="$route.params.id" />
    <AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
    <div class="dashboard-header d-flex flex-wrap justify-content-between align-items-end pb-20">
        <div class="w-100-vsm">
            <h2 class="font-28 font-34-sm-big font-w-300">Create Voucher Program</h2>
            <p class="font-14 color-light2">
                Create voucher program for your recipient or audience to give them discount on purchasing your services
                or
                goods.
            </p>
        </div>
        <div class="w-100-vsm">
            <kbutton :fill-mode="'flat'" :class="'pl-0-vsm'" @click="doNavigateToVoucherHomepage">Cancel</kbutton>
        </div>
    </div>

    <div class="dashboard-content max-height-100-247 overflow-y-auto p-0">
        <div class="create-voucher-wrap d-flex justify-content-between">
            <div class="w-50-p">
                <div class="create-voucher-left p-48">
                    <div class="k-form-field mb-2">
                        <label class="font-16">Voucher Program Name *</label>
                        <k-input type="text" v-model="voucherName" placeholder="AFL Summer League 2022"
                            @keyup="errors.Name[0]=''" aria-required="true">
                        </k-input>
                        <span class="k-form-error">{{this.errors?.Name?.[0]}}</span>
                    </div>
                    <p class="font-16">How do you want your audience to receive vouchers?</p>

                    <div class="voucher-tab-menu-items mb-5">
                        <div ref="createWithEmail" @click="voucherTypeClickHandler(1)" class="single active">
                            <h4 class="font-19 font-w-400">Voucher code in the email</h4>
                            <p class="font-16 color-light2">A generic email will be sent to the user consisting of the
                                voucher code.
                            </p>
                        </div>
                        <div ref="createWithBarcode" @click="voucherTypeClickHandler(2)" class="single">
                            <h4 class="font-19 font-w-400">Voucher code and barcode in an email</h4>
                            <p class="font-16 color-light2">A generic email will be sent to the user consisting of the
                                voucher code
                                and the barcode for scanning.</p>
                        </div>
                        <div ref="createWithDigitalPss" @click="voucherTypeClickHandler(3)" class="single">
                            <h4 class="font-19 font-w-400">Voucher code, barcode and digital pass in an email</h4>
                            <p class="font-16 color-light2">A generic email will be sent to the user consisting of the
                                voucher code,
                                barcode, and the link to digital pass.</p>
                        </div>
                    </div>

                    <kbutton v-if="hasCreateAccess()" :theme-color="'primary'" :class="'w-254 w-100-sm'" @click="handleCreateEvent">Continue to Create
                    </kbutton>
                </div>
            </div>
            <div class="w-50-p">
                <div class="create-voucher-right p-44 h-100">
                    <div v-if="this.creationTypeSerial != 4" class="create-voucher-content-single p-42 bg-white ">
                        <div class="font-16">
                            Hi,
                            <br><br>
                            Congratulations! You have received a gift voucher worth $100.00. <br><br>
                            <p v-if="creationTypeSerial === 1"> This gift voucher has been sent to you by club,
                                {{clubName}}.
                                Use
                                the redemption code provided below while purchasing in
                                stores to redeem this gift. Redeem your voucher before 18/04/2022 to get exciting
                                discounts on your purchases.</p>
                            <p v-if="creationTypeSerial === 2"> This gift voucher has been sent to you by club,
                                {{clubName}}.
                                Use
                                the redemption code provided below while purchasing in
                                stores to redeem this gift. You can also show the barcode to the representative at the
                                cash counter. Redeem your voucher before 18/04/2022 to get exciting discounts on your
                                purchases. </p>
                            <p v-if="creationTypeSerial === 3"> This gift voucher has been sent to you by club,
                                {{clubName}}.
                                Use
                                the
                                redemption code provided below while purchasing in stores to redeem this gift. You can
                                also
                                show the barcode to the representative at the cash counter. In addition, a digital pass
                                has
                                been provided for your voucher to save in your Google Pay or Apple Wallet. Redeem your
                                voucher before 18/04/2022 to get exciting discounts on your purchases.</p>
                            Voucher Code : <strong>********************</strong>
                            <br>
                            <div v-if="[2,3].includes(this.creationTypeSerial)">
                                <br>
                                <div class="qr-icon">
                                    <img src="../../../../assets/images/barcode-voucher.png" alt="" />
                                </div>
                            </div>

                            <div v-if="this.creationTypeSerial ===3">
                                <br>
                                <p>Click the button below for your Voucher Digital Pass
                                </p>
                                <br>
                                <div>
                                    <kbutton :theme-color="'primary'" @click="" :icon="''"
                                        :class="'pointer-events-none'">
                                        Digital Pass
                                    </kbutton>
                                    <!-- <kbutton :theme-color="'primary'" :class="'me-3 pointer-events-none'" @click="" :icon="''">
                                        <span><svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M13.8651 5.68847C11.6244 5.68739 9.38371 5.68793 7.14306 5.68819C7.14335 6.59892 7.14196 7.50966 7.14361 8.42011C8.44125 8.41982 9.73889 8.41957 11.0363 8.42011C10.8859 9.29283 10.3566 10.0909 9.60608 10.5822C9.13427 10.8931 8.5922 11.0949 8.03334 11.1908C7.47089 11.2849 6.8897 11.2969 6.32864 11.1856C5.75809 11.0741 5.21346 10.8411 4.73834 10.5128C3.97862 9.98971 3.39883 9.22446 3.10114 8.3632C2.79674 7.48721 2.79451 6.51248 3.10253 5.63731C3.31626 5.02049 3.67199 4.4507 4.1393 3.98702C4.71572 3.40875 5.46513 2.99536 6.27255 2.82584C6.96392 2.68122 7.69294 2.70882 8.36982 2.90949C8.94514 3.08066 9.47552 3.3896 9.90993 3.79616C10.3491 3.36826 10.786 2.93792 11.2243 2.50922C11.4542 2.27847 11.6958 2.05783 11.9185 1.82079C11.2528 1.21708 10.4724 0.731799 9.61976 0.425025C8.08468 -0.128603 6.35347 -0.140354 4.80808 0.383797C3.06653 0.968068 1.58362 2.24433 0.761425 3.85747C0.475174 4.41332 0.266184 5.00661 0.140639 5.61686C-0.175198 7.13727 0.0449413 8.75835 0.760583 10.1432C1.22569 11.047 1.89249 11.8506 2.70134 12.4805C3.46442 13.0768 4.35386 13.5173 5.2969 13.7631C6.48685 14.0759 7.75351 14.0688 8.9513 13.8017C10.0338 13.5575 11.058 13.0506 11.8758 12.3108C12.7402 11.5323 13.3567 10.5068 13.6832 9.40467C14.0392 8.2025 14.0883 6.9191 13.8651 5.68847Z"
                                                    fill="white" />
                                            </svg>
                                        </span>
                                        Google Pass
                                    </kbutton>
                                    <kbutton :theme-color="'primary'" :class="'pointer-events-none'" @click="" :icon="''">
                                        <span><svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11.1876 7.43971C11.1705 5.66632 12.5829 4.81576 12.6461 4.77279C11.8523 3.56994 10.6156 3.4049 10.1755 3.38611C9.1239 3.27542 8.12255 4.02809 7.58906 4.02809C7.05667 4.02809 6.23241 3.40233 5.36038 3.41827C4.21323 3.43591 3.15586 4.10976 2.56553 5.17402C1.37418 7.31593 2.26076 10.4902 3.42164 12.2286C3.98918 13.0783 4.66599 14.0345 5.55395 13.9995C6.40978 13.9645 6.73268 13.4258 7.76725 13.4258C8.80183 13.4258 9.09233 13.9995 9.99758 13.9815C10.9179 13.9642 11.5014 13.1156 12.0643 12.2625C12.7158 11.2753 12.9841 10.3195 13 10.2711C12.98 10.2614 11.206 9.55714 11.1876 7.43971Z"
                                                    fill="white" />
                                                <path
                                                    d="M9.48543 2.23524C9.95632 1.64278 10.2754 0.819541 10.1886 0C9.50904 0.0284563 8.68643 0.468106 8.19852 1.06C7.76141 1.5853 7.37921 2.42163 7.48162 3.22637C8.23971 3.28727 9.0129 2.82656 9.48543 2.23524Z"
                                                    fill="white" />
                                            </svg>
                                        </span>
                                        Apple Pass
                                    </kbutton> -->
                                </div>
                            </div>
                            <br>
                            Best wishes,
                            <br>
                            Club Name
                        </div>
                    </div>

                    <div v-if="this.creationTypeSerial === 4" class="p-42">
                        <div class="row">
                            <div class="col-md-6">
                                <h6 class="font-w-700 text-color text-center mb-3">Google Pass</h6>
                                <img src="../../../../assets/images/google-pass-2.png">
                                <section>

                                </section>
                            </div>
                            <div class="col-md-6">
                                <h6 class="font-w-700 text-color text-center mb-3">Apple Pass</h6>
                                <img src="../../../../assets/images/apple-pass-2.png">
                            </div>
                        </div>
                    </div>

                    <div v-if="[3,4].includes(this.creationTypeSerial)"
                        class="create-voucher-slide-group d-flex justify-content-center mt-5">
                        <radiobutton :class="'me-2'" :size="'large'" :name="'group1'" :value="'first'"
                            :checked="this.creationTypeSerial === 3" @change="voucherTypeClickHandler(3)" :label="''" />
                        <radiobutton :class="'me-2'" :size="'large'" :name="'group1'" :value="'second'"
                            hecked="this.creationTypeSerial === 4" @change="voucherTypeClickHandler(4)" :label="''" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>